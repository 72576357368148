import React from "react"
import styled from "styled-components"

import { useTranslation } from "react-i18next"

import SEO from "../components/seo"
import ContentWrapper from "../styles/ContentWrapper"

const StyledSection = styled.section`
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  height: auto;
  background: transparent;
  h1 {
      font-size: 1.5rem;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    max-width: 36rem;
    margin: 0;
    padding: 0;
    height: 100%;
  }
`

const NotFoundPage = () => {
  
  const { t, i18n } = useTranslation()
  
  return (
    <>
      <SEO lang={i18n.language} title="404: Not found" meta={[{ name: 'robots', content: 'noindex' }]} />
      <StyledSection>
        <StyledContentWrapper>
          <h1>{t('general.notFound')}</h1>
          <p>{t('general.notFoundDescription')}</p>
        </StyledContentWrapper>
      </StyledSection>
    </>
  )
}

export default NotFoundPage
